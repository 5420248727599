import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";

const Content = props => <React.Fragment>
    <Row className="content">
        <Col>
            {props.children}
        </Col>
    </Row>
</React.Fragment>

Content.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}


export default React.memo(Content);